import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import "../style/Addfixedmenu.css";
import axios from "axios";

function Addfixedmenus({ open, handleCloses ,GetOverAllMenusItems}) {
  const [dishname, setDishname] = useState("");
  if (!open) return null;

  const handleMenuSave = () => {
    if (dishname.trim() === "") {
      alert("Menu name cannot be empty");
      return;
    }

    fetch(`${config.apiBaseUrl}/menus/insert_fixedmenus/${dishname}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Menu Name already Exists") {
          toast.error(
            "Menu name already exists, please choose a different name"
          );
        }else {
          toast.success("Fixed menu created successfully");
          setDishname("");
      
          setTimeout(() => {
              GetOverAllMenusItems(); 
              handleCloses();
          }, 1500); 
      }
      
      })
      .catch((error) => {
        toast.error("An error occurred while creating the menu");
      });
  };

  return (
    <>
      {open && (
        <div className="popup-overlaycataory">
          <div className="menusopenpopup">
            <h5 className="menuheadx text-center m1-3">Add Fixed New Menus</h5>
            <div className="">
              <div className="row d-flex justify-content-center mt-3">
                <div className="col-lg-12 col-md-10 col-sm-12 col-12">
                  <input
                    type="text"
                    value={dishname}
                    onChange={(e) => setDishname(e.target.value)}
                    placeholder="Enter New Fixed Menus Name"
                    className="form-group-inputsadd"
                  />
                </div>
              </div>
              <div className="button-groupadd">
              <button
                  className="closecatary-savebtn"
                  onClick={() => {
                    handleCloses();
                    GetOverAllMenusItems(); 
                  }}
                >
                  Close
                </button>
                <button
                  className="catary-savebtn"
                  onClick={handleMenuSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
  
}

function Addfixedcatgry({catagryopen, handlecatagryCloses,Fixed_menu_id,GetOverAllMenusItems}) {

  const [dishcatagray, setDishcatagray] = useState("");
  if (!catagryopen) return null;
  const handleInsert = async () => {

    if (dishcatagray.trim() === "") {
        alert("Category name cannot be empty");
        return;
    }

    if (!Fixed_menu_id) {
        alert("Fixed menu ID is required");
        return;
    }

    try {
        const response = await axios.post(`${config.apiBaseUrl}/menus/insert_catergory_in_fixedmenu`, {
            category_name: dishcatagray,
            fixed_menu_id:Fixed_menu_id, 
        });

        if (response.status === 201) {
            toast.success("Category inserted successfully");
            setDishcatagray("");
            setTimeout(() => {
              GetOverAllMenusItems(); 
              handlecatagryCloses();
          }, 1500); 
        }
    } catch (error) {
        console.error("Error details:", error); 
        toast.error("An error occurred while creating the menu");
    }
};

  return(
    <>
    {catagryopen && (
        <div className="popup-overlaycataory">
          <div className="menusopencatagry">
            <h5 className="menuheadxcatagry">Add Fixed Catagaryname</h5>
           
              <div className="row d-flex justify-content-center mt-3">
                <div className="col-lg-11 col-md-10 col-sm-12 col-12">
                  <input
                    type="text"
                    value={dishcatagray}
                    onChange={(e) => setDishcatagray(e.target.value)}
                    placeholder="Enter New Fixed catagaryname"
                    className="form-group-inputcatary p-2"
                  />
                </div>
              </div>
              <div className="button-groucatary">
                <button className="closecatary-savebtn"  onClick={() => {
                    handlecatagryCloses();
                    GetOverAllMenusItems(); 
                  }}>
                  Close
                </button>
                <button
                  className="catary-savebtn"
                  onClick={handleInsert}
                >
                  Save
                </button>
              </div>
         
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  )

}


export  {Addfixedcatgry,  Addfixedmenus};
