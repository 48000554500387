import React, { useState, useEffect } from "react";
import "../style/addish.css";
import "../style/Editfixedmenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChevronRight,  faChevronDown,  faPlus,  faXmark} from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { PulseLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import axios from "axios";
import EditFxMenu from "../pages/catagory/Editfixedmenu.js";
import { Addfixedmenus, Addfixedcatgry } from "../pages/Addfixedmenu";
import iconfood from "../../assets/icon food.svg";

function Fixedmenus() {
  let [color] = useState("#ffa500");
  const [menus, setMenus] = useState([]);
  const [isOpen, setIsOpen] = useState({});
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleCloses = () => setOpen(false);
  const [catagryopen, setCatagryopen] = useState(false);
  const handlecatagryCloses = () => setCatagryopen(false);
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const [Childmenu, setChildmenu] = useState(false);
  const [childmenuData, setChildmenuData] = useState([]);
  const [childmenuCategoryName, setChildmenuCategoryName] = useState("");
  const [selectedFixedMenuId, setSelectedFixedMenuId] = useState(null);
  const [catName, setCatName] = useState("");

  const handlecatagryopen = (Fixed_menu_id) => {
    setSelectedMenuId(Fixed_menu_id);
    setCatagryopen(true);
  };

  const handleShow = (item, Menu_id) => {
    let Catogory_id = item.Category_fixed_menu_id;
    setSelectedItem([{ Catogory_id: Catogory_id, Menu_id: Menu_id }]);
    setShow(true);
    setCatName(item.Category_name);
  };

  const handleClose = () => {
    GetOverAllMenusItems();
    setShow(false);
  };

  const handlechildmenuMenu = (menus, categoryName) => {
    setChildmenuData(menus);
    setChildmenuCategoryName(categoryName);
    setChildmenu(true);
  };

  const handleCloseChildmenu = () => {
    setChildmenu(false);
  };

  console.log(childmenuCategoryName);

  const GetOverAllMenusItems = () => {
    fetch(`${config.apiBaseUrl}/menus/get_overall_fixed_menus_items`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setMenus(data);
        setLoading(false);
        const initialIsOpen = data.reduce((acc, menu) => {
          acc[menu.Fixed_menu_name] = false;
          return acc;
        }, {});
        setIsOpen(initialIsOpen);
      })
      .catch((error) => {
        console.error("Error fetching menus:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetOverAllMenusItems();
  }, []);

  const toggleAccordion = (menu) => {
    let menuName = menu.Fixed_menu_name;
    setIsOpen((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));

    setSelectedFixedMenuId(menu.Fixed_menu_id);
  };

  const handleDelete = async (Menu_Id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete the whole fixed menu?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/menus/Delete_Fixed_Menu`,
        { menu_id: Menu_Id }
      );
      if (response.status === 200) {
        toast.success("Delete successfully Fixed menu");
        GetOverAllMenusItems();
      } else {
        toast.error("Failed to delete the menu.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.error || "An unexpected error occurred"
      );
    }
  };

  const handleDeletecataory = async (Category_fixed_menu_id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this category?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `${config.apiBaseUrl}/menus/delete_catergory_in_fixedmenu/${Category_fixed_menu_id}`
      );
      if (response.status === 200) {
        toast.success("Deleted category fixed menu successfully");
        GetOverAllMenusItems();
      } else {
        toast.error("Failed to delete the category menu.");
      }
    } catch (error) {
      console.error("Delete category error:", error);
      if (error.response) {
        toast.error(
          error.response.data.error || "An unexpected error occurred"
        );
      } else {
        toast.error("Network error or request failed");
      }
    }
  };

  return (
    <div className="menus">
      {open && (
        <Addfixedmenus
          open={open}
          handleCloses={handleCloses}
          GetOverAllMenusItems={GetOverAllMenusItems}
        />
      )}

      {catagryopen && (
        <Addfixedcatgry
          catagryopen={catagryopen}
          handlecatagryCloses={handlecatagryCloses}
          Fixed_menu_id={selectedMenuId}
          GetOverAllMenusItems={GetOverAllMenusItems}
        />
      )}

      <ToastContainer autoClose={2000} />
      <h5 className="menuheadx mt-2">Fixed Menus</h5>
      <div className="addlistdiv">
        <button onClick={handleOpen} className="Adddishbtn">
          Add Fixed Menu
        </button>
      </div>

      {loading ? (
        <div className="spinner-container">
          <div className="sweet-loading">
            <PulseLoader
              color={color}
              loading={loading}
              size={12}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      ) : (
        <div className="row mt-3">
          {menus.map((menu) => (
            <div
              key={menu.Fixed_menu_id}
              className="col-lg-6 col-md-6 col-12 mt-3"
            >
              <div className="generalacardination">
                <div className="row">
                  <div className="text-start col-lg-11 col-md-10 col-10">
                    <div className="d-flex align-items-center">
                      <h6 className="headtexthelp"> {menu.Fixed_menu_name} </h6>
                      <span className="ms-2">
                        <FontAwesomeIcon
                          onClick={() => handlecatagryopen(menu.Fixed_menu_id)}
                          className="fixedaddbtn"
                          icon={faPlus}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-1 col-md-2 col-2 chevronbutton ">
                    <FontAwesomeIcon
                      className={`chevron ${
                        isOpen[menu.Fixed_menu_name] ? "rotate" : ""
                      }`}
                      icon={
                        isOpen[menu.Fixed_menu_name]
                          ? faChevronDown
                          : faChevronRight
                      }
                      onClick={() => toggleAccordion(menu)}
                      aria-expanded={isOpen[menu.Fixed_menu_name]}
                    />
                  </div>
                </div>
                <div
                  className={`accordion-collapse collapse ${
                    isOpen[menu.Fixed_menu_name] ? "show" : ""
                  }`}
                >
                  <div className="accadtext-body row  ">
                    {menu?.items?.length > 0 ? (
                      menu.items.map((item, index) => (
                        <div
                          key={item?.Category_fixed_menu_id || index}
                          className="col-lg-6 col-md-6 col-12"
                        >
                          <div className="d-flex catagroryhover">
                            <p
                              className="fixedmenu-textdata ellipsisadd"
                              onClick={() => handlechildmenuMenu(item?.menus)}
                            >
                              <>
                                <svg
                                  width="13"
                                  height="15"
                                  className="bulleticonfixedmenu"
                                  viewBox="0 0 13 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.92807 14.1218C3.79103 14.0132 3.75828 13.8184 3.85217 13.6708L7.91747 7.28189C7.98864 7.17003 7.98864 7.02712 7.91747 6.91507L3.85217 0.526192C3.75828 0.378555 3.79103 0.183978 3.92807 0.0751433C4.05242 -0.0234704 4.22826 -0.0234704 4.35262 0.0751433L12.8707 6.83084C13.0431 6.9675 13.0431 7.22927 12.8707 7.36612L4.35262 14.1218C4.22826 14.2204 4.05242 14.2204 3.92807 14.1218ZM4.08176 2.87172L0.55495 0.0747647C0.493056 0.0257418 0.41621 0 0.341634 0C0.266869 0 0.192294 0.0255526 0.1304 0.0747647C-0.00834038 0.183599 -0.0403281 0.377798 0.0535535 0.527138L4.1181 6.91507C4.19059 7.02807 4.19059 7.17116 4.1181 7.28208L0.0535535 13.6698C-0.0403281 13.8192 -0.00834038 14.0134 0.1304 14.1222C0.254188 14.2204 0.42908 14.2204 0.55495 14.1222L4.0776 11.3271L6.77026 7.09829L4.08176 2.87172Z"
                                    fill="#E18722"
                                  />
                                </svg>
                                {item?.Category_name}
                              </>
                            </p>
                            <span className="ms-2">
                              <FontAwesomeIcon
                                onClick={() =>
                                  handleShow(item, selectedFixedMenuId)
                                }
                                className="fixedcatagoryeditbtn"
                                icon={faPenToSquare}
                                aria-label="Edit category"
                              />
                            </span>
                            <span className="ms-1">
                              <FontAwesomeIcon
                                onClick={() =>
                                  handleDeletecataory(
                                    item?.Category_fixed_menu_id
                                  )
                                }
                                className="fixedcatagorydeletebtn"
                                icon={faTrashCan}
                                aria-label="Delete category"
                              />
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="no-data-message mb-0">No data available</p>
                    )}
                  </div>
                  {Childmenu && (
                    <div className="overlayadd">
                      <div className="childmenucatagry">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="text-dark mb-0">
                            Category Fixed Menu Name
                          </h5>
                          <h6
                            onClick={handleCloseChildmenu}
                            className="mb-0 closeiconchildcatagory"
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </h6>
                        </div>

                        <div className="row d-flex pt-2">
                          {childmenuData && childmenuData.length > 0 ? (
                            childmenuData.map((childmenu, index) => (
                              <div
                                key={childmenu?.childmenu_id || index}
                                className="col-lg-6 col-md-6 col-sm-6 col-12"
                              >
                                <p className="childmenucatagryelipses mb-1">
                                  <img
                                    src={iconfood}
                                    alt="icon"
                                    className="mr-2 iconfoods"
                                  />
                                  {childmenu?.childmenu_Name ||
                                    "No name available"}
                                </p>
                              </div>
                            ))
                          ) : (
                            <p className="no-data-message">
                              No more data available
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row ">
                    <div className=" d-flex justify-content-end">
                      <FontAwesomeIcon
                        className="fixedmenudeletebtn"
                        icon={faTrashCan}
                        onClick={() => handleDelete(menu.Fixed_menu_id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {show && (
            <div
              className="modal fade show custom-backdrop"
              style={{ display: "block" }}
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <EditFxMenu
                selectedItem={catName}
                rowData={selectedItem}
                handleClose={handleClose}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default Fixedmenus;
