import React, { useEffect, useState } from 'react';

import './history.css';
import { useNavigate } from 'react-router-dom';
import config from "../config/config";

function Chathistory() {
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchChatHistory = async () => {
    try {
      const response = await fetch(
        `${config.apiBaseUrl}/history/getphonenumbers`);
      const data = await response.json();
      setChatHistory(data);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchChatHistory();
  }, []);

  const handleViewChat = (phonenumber) => {
    navigate(`/chat-history/${phonenumber}`);
  };

  return (
    <div className='manager'>
      <h4 className='chefs-textsmall'>User Chat history</h4>
      <div className='container mt-3'>
      </div>
      <div className='row mt-2'>
        <div className="eventsdiv ">
          <div className="table-responsive">
            <table className="table text-center">
              <thead>
                <tr className="tabletext">
                  <th>S.NO</th>
                  <th>Mobile number</th>
                  <th>View chat</th>
                </tr>
              </thead>
              <tbody className="table">
                {isLoading ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      <div className="loading-spinner">Loading...</div>
                    </td>
                  </tr>
                ) : chatHistory.length > 0 ? (
                  chatHistory.map((event, index) => (
                    <tr key={index} className="mt-3">
                      <td>{index + 1}</td>
                      <td>{event}</td>
                      <td>
                        <button
                          type="button"
                          className="historyview "
                          onClick={() => handleViewChat(event)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No History Found
                    </td>
                  </tr>
                )}
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Chathistory;
