import React, { useState } from 'react';
import '../catagory/Addcatagrory.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import config from "../../config/config.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Addsubcategory({ openSubPopup, handleCloses, categoryId,fetchMenuDetails }) {
  const [subcategoryName, setSubcategoryName] = useState('');

  if (!openSubPopup) return null;

  const handleSave = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/menus/insert_updatefullmenu_subcategory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          category_id: categoryId,
          Subcategory_name: subcategoryName,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        toast.success(result.message);
        setSubcategoryName('');
        setTimeout(() => {
          handleCloses();
          fetchMenuDetails(); 
        }, 2000); 
      } else {
        toast.error(result.message || 'Failed to save subcategory.');
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message || "An error occurred. Please try again.";
      toast.error(`Error saving subcategory: ${errorMessage}`);
  }
  };

  return (
    <div className="popup-overlaysubcataory">
      <div className="menusopensubcatagrymain">
        <div className="d-flex justify-content-between">
          <h5>Add Subcategory Name</h5>
          <h6 onClick={handleCloses} className="text-end removicpnd">
            <FontAwesomeIcon icon={faXmark} />
          </h6>
        </div>
        <div className="menusopensubcatagry">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-12 p-2">
              <input
                type="text"
                value={subcategoryName}
                onChange={(e) => setSubcategoryName(e.target.value)}
                placeholder="Enter Subcategory Name"
                className="form-group-inputcatary p-2"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button onClick={handleSave} className="subcatary-savebtn">
              Save
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
