import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import config from "../config/config";
import Modall from "./Outsorcingvendor";

function Adddish() {
  const [categorySelected, setCategorySelected] = useState("");
  const [insertCategories, setInsertCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState("");
  const [dishName, setDishName] = useState("");
  const [menuType, setMenuType] = useState("");
  const [Uoms, setUoms] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientData, setIngredientData] = useState([]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMenuTypeChange = (e) => {
    setMenuType(e.target.value);
  };

  const handleSaveDish = async () => {
    if (!subCategorySelected || !dishName || !menuType) {
      toast("Please fill in all fields");
      return;
    }
    if (ingredients.length === 0) {
      toast("Please add at least one ingredient");
      return;
    }
  
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/menus/insertMenuDetails`,
        {
          subCategorySelected,
          dishName,
          menuType,
        }
      );
  
      if (response.status === 201) {
        const { menu_id } = response.data;
        toast("Menu details inserted successfully");
        setDishName("");
        setMenuType("");  
        setSubCategorySelected("");
        saveIngredients(menu_id);
      } else {
        toast("Failed to insert menu details");
      }
    } catch (error) {
      console.error("Error inserting menu details:", error);
      toast("An error occurred while saving the dish");
    }
  };
  
  
  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setCategorySelected(categoryId);

    const selectedCat = insertCategories.find(
      (cat) => cat.category_id === parseInt(categoryId)
    );
    setSelectedSubCategory(selectedCat ? selectedCat.sub_categories : []);
    setSubCategorySelected("");
  };

  const handleSubCategoryChange = (e) => {
    setSubCategorySelected(e.target.value);
  };

  const handleDishNameChange = (e) => {
    setDishName(e.target.value);
  };

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_ingredients_unit`)
      .then((response) => {
        setUoms(response.data);
        console.log("Fetched UOMs:", response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the UOMs!", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/Get_Vendor_ingredient_details`)
      .then((response) => {
        setIngredientData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleTypeChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].type = value;

    const selectedTypeData = ingredientData.find(
      (type) => type.vendor_id === parseInt(value)
    );
    if (selectedTypeData) {
      newIngredients[index].availableIngredients = selectedTypeData.ingredients;
    } else {
      newIngredients[index].availableIngredients = [];
    }

    setIngredients(newIngredients);
  };

  const handleItemChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].name = value;
    setIngredients(newIngredients);
  };

  const handleUOMChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].uom = value;
    setIngredients(newIngredients);
  };

  const handleIngredientChange = (index, key, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index][key] = value;
    setIngredients(newIngredients);
  };

  const addIngredient = () => {
    setIngredients([
      ...ingredients,
      { type: "", name: "", quantity: "", uom: "", availableIngredients: [] },
    ]);
  };

  const removeIngredient = (index) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients.splice(index, 1);
    setIngredients(updatedIngredients);
  };

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_fullmenu_subcatagorizes_list`)
      .then((response) => {
        setInsertCategories(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the categories!", error);
        toast.error("Error fetching categories, please try again later.");
      });
  }, []);

  const saveIngredients = (menu_id) => {
    const ingredientsData = ingredients.map((ingredient) => ({
      p_Menu_id: menu_id,
      p_Name: ingredient.name,
      p_UOM: ingredient.uom,
      p_Vendor_id: parseInt(ingredient.type, 10),
      p_Quantity_value: parseFloat(ingredient.quantity),
    }));
  
    axios
      .post(`${config.apiBaseUrl}/menus/insertItemIngredientsBulk`, {
        ingredients: ingredientsData,
      })
      .then((response) => {
        console.log("All ingredients saved:", response.data);
        toast.success("All ingredients saved successfully!");
        setIngredients([]); 
      })
      .catch((error) => {
        console.error("Error saving ingredients:", error);
        toast.info("No ingredients fill. After add full menu  ingredients.");
      });
  };


  
  return (
    <div className="menus">
      <ToastContainer />
      <h5 className="menuheadx mt-2">Add  Dish</h5>

      <div className="addlistdiv">
          <button onClick={handleOpen} className="Adddishbtn" data-toggle="modal" data-target=".bd-example-modal-lg">Add Oursource vendor</button>
      </div>
      {open && <Modall open={open} handleClose={handleClose} />}
      <div className="dishdivchoose mt-3">
        <h5 className="adddishead">Create menus</h5>
        <div className="dishdivchoose">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-6">
              <h6 className="catagris">Categories</h6>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio1"
                  name="categoryType"
                  value="veg"
                  checked={menuType === "veg"}
                  onChange={handleMenuTypeChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Veg
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="inlineRadio2"
                  name="categoryType"
                  value="non-veg"
                  checked={menuType === "non-veg"}
                  onChange={handleMenuTypeChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Non-veg
                </label>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 ">
              <h6 className="catagris">Main categories</h6>
              <select
                className="form-select"
                value={categorySelected}
                onChange={handleCategoryChange}
              >
                <option value="" disabled>
                  Choose Main categories
                </option>
                {insertCategories.map((category) => (
                  <option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-4  col-md-4 col-sm-12 col-12">
              <h6 className="catagris">Sub categories</h6>
              <select
                className="form-select"
                value={subCategorySelected}
                onChange={handleSubCategoryChange}
              >
                <option value="" disabled>
                  Choose Sub categories
                </option>
                {selectedSubCategory.map((subcategory) => (
                  <option key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row form-group mt-4">
            <div className=" col-lg-2 col-md-2 col-sm-0"></div>
            <div className=" col-lg-8 col-md-8 col-sm-12 col-12">
              <h6 className="ingred mt-4">Enter Dish Name</h6>
              <input
                type="text"
                value={dishName}
                placeholder="Enter dish name"
                className="form-group-input"
                onChange={handleDishNameChange}
              />
            </div>
            <div className=" col-lg-2 col-md-2 col-sm-0"></div>
          </div>
          <p className="adddishead mt-4"> Add Ingredient</p>
          {ingredients.map((ingredient, index) => (
            <div className="row form-group mt-3" key={index}>
              <div className="col-lg-3 col-6 mt-1">
                <select
                  className="form-select"
                  value={ingredient.type}
                  onChange={(e) => handleTypeChange(index, e.target.value)}
                >
                  <option value="" disabled>
                    Ingredient Type
                  </option>
                  {ingredientData.map((ingredientType) => (
                    <option
                      key={ingredientType.vendor_id}
                      value={ingredientType.vendor_id}
                    >
                      {ingredientType.type}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-3 col-6 mt-1">
                <select
                  className="form-select"
                  value={ingredient.name}
                  onChange={(e) => handleItemChange(index, e.target.value)}
                  disabled={!ingredient.availableIngredients.length}
                >
                  <option value="" disabled>
                    Ingredient Name
                  </option>
                  {ingredient.availableIngredients.map(
                    (availableIngredient) => (
                      <option
                        key={availableIngredient.ingredient_name}
                        value={availableIngredient.ingredient_name}
                      >
                        {availableIngredient.ingredient_name}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="col-lg-3 col-6 mt-1">
                <input
                  type="text"
                  value={ingredient.quantity}
                  className="form-group-input"
                  onChange={(e) =>
                    handleIngredientChange(index, "quantity", e.target.value)
                  }
                  placeholder="Quantity"
                />
              </div>
              <div className="col-lg-2 col-5 mt-1">
                <select
                  className="form-select"
                  value={ingredient.uom}
                  onChange={(e) => handleUOMChange(index, e.target.value)}
                >
                  <option value="" disabled>
                    Choose Unit
                  </option>
                  {Uoms.map((unit) => (
                    <option key={unit.UOM} value={unit.UOM}>
                      {unit.UOM}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-1 col-1 mt-1 p-0 d-flex justify-content-center">
                <FontAwesomeIcon
                  className="pt-3 remove-btn"
                  onClick={() => removeIngredient(index)}
                  icon={faXmark}
                />
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-center mt-4">
            <button className="addbutton" onClick={addIngredient}>
              Add Ingredient
            </button>
            <button
              className="saveincredite"
              onClick={() => {
                handleSaveDish(); 
              }}
            >
              Save Menus
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adddish;
