import React, { useState, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./history.css";
import config from "../config/config";

const ChatHistoryPage = () => {
  const { event } = useParams();
  const [chatHistory, setChatHistory] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [color] = useState("#ffa500");

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await axios.get(
          `${config.apiBaseUrl}/history/get_chat_history/${event}`
        );
        setChatHistory(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchChatHistory();
  }, [event]);

  if (loading) {
    return (
      <div className="spinner-container">
        <PulseLoader
          color={color}
          loading={loading}
          size={12}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  if (error) {
    return <div className="Error">Error: {error.message}</div>;
  }

  return (
    <div className="chef">
      <h2 className="chefs-textsmall">User Chat History</h2>
      <div className="row">
        <div className="col-lg-1 col-md-1 col-0"></div>
        <div className="col-lg-10 col-md-10 col-12">
          <div className="mt-4">
            {chatHistory.length > 0 ? (
              chatHistory.map((chat, index) => (
                <div className={`ChatMessage ${chat.type}`} key={index}>
                  {chat.type === "human" ? (
                    <div className="Message">
                      <span className="UserLabel">User:</span> {chat.human}
                      <div className="Timestampus ">Date:{chat.date}</div>
                    </div>
                  ) : (
                    <div className="Response">
                      <span className="BotLabel">AI Bot:</span> {chat.ai}
                      <div className="Timestamp">Date:{chat.date}</div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div>No chat history found.</div>
            )}
          </div>
        </div>
        <div className="col-lg-1 col-md-1 col-0"></div>
      </div>
    </div>
  );
};

export default ChatHistoryPage;
