import React, { useState, useEffect, useRef, useCallback } from "react";
import "../style/manager.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import uplode from "../management/uplodeimg.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import { PulseLoader } from "react-spinners";
import defaultImage from '../../assets/Ellipse.png';

export const Manager = () => {
  const [addManagerOpen, setAddManagerOpen] = useState(false);
  const [cardMenuOpen, setCardMenuOpen] = useState(null);
  const [names, setNames] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editcardOpen, setEditCardOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const menuRef = useRef();
  let [color] = useState("#ffa500");

  const fetchManagerDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiBaseUrl}/managers/getManagerdetails`
      );
      if (response.ok) {
        const data = await response.json();
        setManagers(Array.isArray(data) ? data : [data]);
      } else {
        const errorData = await response.json();
        console.log(errorData.message);
      }
    } catch (error) {
      console.error("Error fetching manager details:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSave = useCallback(async () => {
    if (!names) {
      toast.error("Name is required.");
      return;
    }

    // Validate phone number
    const mobileNumberToSend = phoneNumber || selectedManager?.Emp_MobileNumber;
    const phoneRegex = /^[0-9]{10}$/; // Regex for 10-digit number
    if (!phoneRegex.test(mobileNumberToSend)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }

    const formData = new FormData();
    formData.append("Emp_Name", names);
    formData.append("Role", "Event Manager");
    formData.append("Emp_MobileNumber", `91${mobileNumberToSend}`);

    if (imageFile) {
      formData.append("file", imageFile);
    } else if (selectedManager?.photo) {
      formData.append("photo", selectedManager.photo);
    }

    try {
      let response;
      if (selectedManager) {
        response = await fetch(
          `${config.apiBaseUrl}/managers/update_employee/${selectedManager.Emp_Id}`,
          {
            method: "PUT",
            body: formData,
          }
        );
      } else {
        response = await fetch(
          `${config.apiBaseUrl}/managers/insert_employee`,
          {
            method: "POST",
            body: formData,
          }
        );
      }

      const result = await response.json();

      if (result.message.includes("Mobile Number already exists")) {
        toast.error("Mobile number already exists. Please use a different number.");
      } else if (response.ok && result.status === "success") {
        toast.success(selectedManager ? "Profile updated successfully!" : "Profile saved successfully!");
        setNames("");
        setPhoneNumber("");
        setProfileImage(null);
        setImageFile(null);
        setAddManagerOpen(false);
        setEditCardOpen(false);
        fetchManagerDetails();
      } else {
        toast.error(result.message || "Failed to save the profile.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while saving the profile.");
    }
  }, [names, phoneNumber, imageFile, fetchManagerDetails, selectedManager]);



  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setProfileImage(URL.createObjectURL(file));
    setImageFile(file);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setAddManagerOpen(false);
      setEditCardOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Open the Add New Manager form
  const handleAddOpen = () => {
    setAddManagerOpen(!addManagerOpen);
    setSelectedManager(null);
    setNames("");
    setPhoneNumber("");
    setProfileImage(null);
    setImageFile(null);
  };

  const handleCardMenuToggle = (index) => {
    setCardMenuOpen(cardMenuOpen === index ? null : index);
  };

  const deleteEmployee = (Emp_Id) => {
    axios
      .delete(`${config.apiBaseUrl}/managers/delete_employee/${Emp_Id}`)
      .then((response) => {
        toast.success(response.data.message);
        fetchManagerDetails();
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message || error.response.data.error);
        } else {
          toast.error("An error occurred while deleting the employee.");
        }
      });
  };

  // Open the Edit Manager form
  const handleEditCardOpen = (manager) => {
    setSelectedManager(manager);
    setNames(manager.Emp_name);
    const mobileNumber = manager.Emp_MobileNumber.startsWith("91")
    ? manager.Emp_MobileNumber.substring(2)
    : manager.Emp_MobileNumber;
    setPhoneNumber(mobileNumber);
    setProfileImage(manager.photo);
    setEditCardOpen(true);
  };

  useEffect(() => {
    fetchManagerDetails();
  }, [fetchManagerDetails]);

  return (
    <div className="manager">
      <ToastContainer />
      <p className="manager-texth">Team Manager</p>
      <div className="d-flex justify-content-end">
        <button className="Addmanager" onClick={handleAddOpen}>
          Add New
        </button>
      </div>
      {addManagerOpen && (
        <div className="popup-overlay">
          <div ref={menuRef} className="manager-content">
            <h4 className="Team-management">Team Management</h4>
            <form className="sheulde-orderform mt-4">
              <div className="d-flex justify-content-center">
                <label htmlFor="imageUpload" className="imageLabel">
                  {profileImage ? (
                    <img src={profileImage} alt="Profile" className="image" />
                  ) : (
                    <div className="placeholderteam">
                      <span>
                        <img className="uploadIcon" src={uplode} alt="Upload" />
                      </span>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="imageUpload"
                  className="imageUploadInput"
                  onChange={handleImageUpload}
                />
              </div>
              <label className="form-label" htmlFor="nameInput">Name</label>
              <input
                type="text"
                placeholder="Name"
                value={names}
                onChange={(e) => setNames(e.target.value)}
                required
                className="form-control"
              />
              <label className="form-label" htmlFor="phoneInput">Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="form-control"
              />
              <button
                type="button"
                onClick={handleSave}
                className="Team-managementbtn"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Managers List */}
      <div className="row mt-3">
        {loading ? (
          <div className="spinner-container">
            <div className="sweet-loading">
              <PulseLoader
                color={color}
                loading={loading}
                size={12}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        ) : managers.length > 0 ? (
          managers.map((manager, index) => (
            <div className="col-sm-6 col-12 col-lg-3 p-2" key={index}>
              <div className="card manager-backgroundimg">
                <h6 className="text-end iconelips">
                  <FontAwesomeIcon
                    onClick={() => handleCardMenuToggle(index)}
                    icon={faEllipsis}
                  />
                </h6>
                {cardMenuOpen === index && (
                  <>
                    <div className="managerscardoption">
                      <p className="actionedit" onClick={() => handleEditCardOpen(manager)}>Edit</p>
                      <p onClick={() => deleteEmployee(manager.Emp_Id)} className="actiondelete">Delete</p>
                    </div>
                    {editcardOpen && selectedManager && (
                      <div className="manageredit-overlay">
                        <div ref={menuRef} className="manageredit-content">
                          <h4 className="Team-management">Edit Manager</h4>
                          <form className="mt-4">
                            <div className="d-flex justify-content-center">
                              <label htmlFor="imageUpload" className="imageLabel">
                                {profileImage ? (
                                  <img src={profileImage} alt="Profile" className="image" />
                                ) : (
                                  <div className="placeholderteam">
                                    <span>
                                      <img className="uploadIcon" src={uplode} alt="Upload" />
                                    </span>
                                  </div>
                                )}
                              </label>
                              <input
                                type="file"
                                id="imageUpload"
                                className="imageUploadInput"
                                onChange={handleImageUpload}
                              />
                            </div>
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              placeholder="Name"
                              value={names}
                              onChange={(e) => setNames(e.target.value)}
                              required
                              className="form-control"
                            />
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              placeholder="Phone Number"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault(); // Prevent non-numeric input
                                }
                              }}
                              maxLength={10} // Restrict input length to 10 characters
                              required
                              className="form-control"
                            />

                            <button
                              type="button"
                              onClick={handleSave}
                              className="Team-managementbtn"
                            >
                              Update
                            </button>
                          </form>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="manager-cardbody">
                  <div className="managerprofile">
                    <img
                      src={manager?.photo || defaultImage}
                      className="managerprofile-img"
                      alt="Manager"
                    />
                  </div>
                  <h2 className="managerheadtext pt-1">{manager.Emp_name}</h2>
                  <p className="manager-textsmall">{manager.Emp_MobileNumber}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-data-message">No managers available.</div>
        )}
      </div>
    </div>
  );
};
