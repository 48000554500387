import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faPeopleRoof, faUserTie, faUtensils, faClockRotateLeft, faDiagramSuccessor, faList} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import grouph from "../../assets/4x1.png";
import { Tooltip } from "react-tooltip";
import "./sidebar.css";

const Sidebar = () => {
  const [visible, setVisible] = useState(false);
  const [menuIcon, setMenuIcon] = useState("jam--menu");
  const [showmanagement, setshowmanagement] = useState(false);
  const [showmenulist, setshowmenulist] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route

  const togglemanagement = () => {
    setshowmanagement(!showmanagement);
  };
  const togglemenulist = () => {
    setshowmenulist(!showmenulist);
  };

  const toggleMenu = () => {
    setVisible(!visible);
    setMenuIcon(menuIcon === "jam--menu" ? "gravity-xmark" : "jam--menu");
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("username");

    navigate("/");
  };

  return (
    <>
      <div className="top-nav">
        <img
          src={require("../../assets/logo.png")}
          alt="logo"
          className="top-logo"
        />
        <img
          src={require(`../../assets/${menuIcon}.png`)}
          alt="menu"
          className={`mobile-nav-btn p-2 ${
            menuIcon === "gravity-xmark" ? "gravity-xmark-icon" : ""
          }`}
          onClick={toggleMenu}
        />
      </div>

      <div className={visible ? "navbar open" : "navbar"}>
        <div className="sidebar__top">
          <div className="sidebar d-flex flex-column justify-content-between bg-white p-4 vh-100">
            <div>
              <ul className="nav link-primary flex-column p-0">
                <li
                  className={`navt-sidetext mt-2 mb-0 ${
                    location.pathname === "/dashboard" ? "active-link" : ""
                  }`}
                >
                  <Link to="/dashboard" className="nav-link side">
                    <FontAwesomeIcon icon={faUserTie} className="me-4 fs-6" />
                    Dashboard
                  </Link>
                </li>

                <li className="navt-sidetext" onClick={togglemenulist}>
                  <Link className="nav-link side">
                    <FontAwesomeIcon icon={faUtensils} className="me-4 fs-6" />
                    Menus list{" "}
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="managemenchevront"
                    />
                  </Link>
                </li>
                {showmenulist && (
                  <>
                   <li  className={`navt-sidetext mt-1 ${location.pathname === "/Add-dish" ? "active-link" : ""  }`} >
                    <Link to="/Add-dish" className="mt-1 nav-link mx-5 side1">
                  
                      Add dish
                    </Link></li>
                    <li  className={`navt-sidetext mt-1 ${location.pathname === "/menus" ? "active-link" : ""  }`} > 
                    <Link to="/menus" className="mt-1 nav-link mx-5 side1">
                      Full Menus
                    </Link></li>
                    <li  className={`navt-sidetext mt-1 ${location.pathname === "/fixedmenus" ? "active-link" : ""  }`} > 
                    <Link to="/fixedmenus" className="nav-link mx-5 side1">
                      Fixed Menus
                    </Link></li>
                    <li  className={`navt-sidetext mt-1 ${location.pathname === "/Ingredient" ? "active-link" : ""  }`} > 
                    <Link to="/Ingredient" className="mx-5 nav-link mx-5 side1">
                      Ingredient
                    </Link></li>
                  </>
                )}
                <li className="navt-sidetext" onClick={togglemanagement}>
                  <Link className="nav-link side">
                    <FontAwesomeIcon
                      icon={faPeopleRoof}
                      className="me-3 fs-6"
                    />
                    Management{" "}
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className="managemenchevront"
                    />
                  </Link>
                </li>
                {showmanagement && (
                  <>
                 
                  <li
                  className={`navt-sidetext mt-1 ${
                    location.pathname === "/Manager" ? "active-link" : ""
                  }`}
                >
                  <Link to="/Manager" className="nav-link mx-5 side1">
                      Manager
                    </Link></li>
                    <li
                  className={`navt-sidetext mt-1 ${
                    location.pathname === "/Vendors" ? "active-link" : ""
                  }`}
                >
                    <Link to="/Vendors" className="nav-link mx-5 side1">
                      Vendors
                    </Link></li>
            
                   
                  </>
                )}
                <li
                  className={`navt-sidetext mt-1 ${
                    location.pathname === "/Orders" ? "active-link" : ""
                  }`}
                >
                  <Link to="/Orders" className="nav-link side">
                    <FontAwesomeIcon
                      icon={faClockRotateLeft}
                      className="me-3 fs-6"
                    />
                    Confirm orders
                  </Link>
                </li>
                <li
                  className={`navt-sidetext mt-1 ${
                    location.pathname === "/Leads" ? "active-link" : ""
                  }`}
                >
                  <Link to="/Leads" className="nav-link side">
                    <FontAwesomeIcon
                      icon={faDiagramSuccessor}
                      className="me-3 fs-6"
                    />
                    Leads orders
                  </Link>
                </li>
                <li
                  className={`navt-sidetext mt-1 ${
                    location.pathname === "/Chat-history" ? "active-link" : ""
                  }`}
                >
                  <Link to="/Chat-history" className="nav-link side">
                    <FontAwesomeIcon icon={faList} className="me-3 fs-6" />
                    Chat history
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="nav link-primary flex-column settbottom p-0">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-auto">
                      <div className="profile">
                        <img
                          src={require("../../assets/person.png")}
                          alt="Profile"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <li className="nav-item">
                  <button
                    onClick={handleLogout}
                    className="nav-link text-center logout-button"
                    data-tooltip-id="logoutTooltip"
                    data-tooltip-content="Logout"
                  >
                    <img
                      className="logoutimg text-center"
                      alt="logout"
                      src={grouph}
                    />
                  </button>
                  <Tooltip
                    id="logoutTooltip"
                    place="right"
                    type="dark"
                    effect="solid"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
