import React, { useState, useEffect } from 'react';
import config from "../../config/config.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditMaincategory({ categoryId, initialCategoryName, handleditcategoryclose, openeditcategoryPopup,   fetchMenuDetails }) {
  const [categoryName, setCategoryName] = useState(initialCategoryName || "");

  useEffect(() => {
    if (initialCategoryName) {
      setCategoryName(initialCategoryName);
    }
  }, [initialCategoryName]);

  if (!openeditcategoryPopup) return null;

  const handleSave = async () => {
    if (!categoryName.trim()) {
      toast.error("Category name cannot be empty.");
      return;
    }

    try {
      const response = await fetch(
        `${config.apiBaseUrl}/menus/insert_update_fullmenu_category`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            Category_Name: categoryName,
            Category_id: categoryId,
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        toast.success(result.message);
        setTimeout(() => {
          handleditcategoryclose();
          fetchMenuDetails();
        }, 1000);
      } else {
        toast.error(result.message || "Failed to save category.");
      }
    } catch (error) {
      toast.error("Error saving category.");
    }
  };

  return (
    <div className="popup-overlaycataory">
      <ToastContainer />

      <div className="menusopencatagry">
        <h5 className="menuheadxcatagry text-center mb-0">Edit Main Category</h5>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-11 col-md-10 col-sm-12 col-11 p-2">
            <input
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Edit Main Category Name"
              className="form-group-inputcatary p-2"
            />
          </div>
        </div>
        <div className="button-grouaddcatary">
          <button className="closecatary-savebtn" onClick={handleditcategoryclose}>
            Close
          </button>
          <button className="catary-savebtn" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditMaincategory;
