import React, { useEffect, useState, useRef } from "react";
import "../style/menus.css";
import axios from "axios";
import config from "../config/config";
import { PulseLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEdit, faPlus, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import Addsubcategory from "./catagory/Addsubcatagory";
import Editsubcategory from "./catagory/EditSubcategory";
import Swal from 'sweetalert2';
import EditMaincategory from "../pages/catagory/Editcategory";

export const Menus = () => {
  let [color] = useState("#ffa500");
  const menuRef = useRef();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [CreatemenuOpen, setCreatemenuOpen] = useState(false);
  const [insertCategories, setInsertCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState("");
  const [dishName, setDishName] = useState("");
  const [menuType, setMenuType] = useState("");
  const [Uoms, setUoms] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [ingredientData, setIngredientData] = useState([]);
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [subcategoryToEdit, setSubcategoryToEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openSubeditPopup, setOpenSubeditPopup] = useState(false);
  const [openSubPopup, setOpenSubPopup] = useState(false);
  const [selectedCategoryIdForSub, setSelectedCategoryIdForSub] = useState(null);

  const [editcategoryId, setEditcategoryId] = useState(null);
  const [categoryNames, setCategoryNames] = useState('');
  const [openeditcategoryPopup, setOpeneditcategoryPopup] = useState(false);

  const handleditcategoryOpen = (categoryId, categoryName) => {
    setEditcategoryId(categoryId);
    setCategoryNames(categoryName);
    setOpeneditcategoryPopup(true);
  };

  const handleditcategoryclose = () => {
    setOpeneditcategoryPopup(false);
    setEditcategoryId(null);
    setCategoryNames(''); // Reset the name here
    fetchMenuDetails();
  };
  const handleOpen = () => { setOpenPopup(true); };

  const handleClose = () => { setOpenPopup(false); setCategoryName(''); fetchMenuDetails(); };

  const handlesubOpen = (subcategoryId) => {
    setSelectedCategoryIdForSub(subcategoryId);
    setOpenSubPopup(true);
  };

  const handleCloses = () => { setOpenSubPopup(false); setSelectedCategoryIdForSub(null); fetchMenuDetails(); };

  const handleCloseseditsub = () => { setOpenSubeditPopup(false); setSelectedCategoryIdForSub(null); fetchMenuDetails(); };

  const handleSubEdit = (subcategory) => { setSubcategoryToEdit(subcategory); setOpenSubeditPopup(true); };

  const handleInsertCategory = async () => {
    try {
      const response = await axios.post(`${config.apiBaseUrl}/menus/insert_update_fullmenu_category`, { Category_Name: categoryName, });
      if (response.status === 200 || response.status === 201) { toast.success(response.data.message); handleClose(); } else {
        toast.error("Failed to add category.");
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status; const message = error.response.data.message || "An error occurred"; toast.error(`Error ${status}: ${message}`);
      } else {
        console.error("Error adding category:", error);
        toast.error("An error occurred while adding the category.");
      }
    }
  };

  const handleDelete = async (categoryId) => {
    const result = await Swal.fire({
      title: "Are you sure you want to delete this category?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        popup: 'custom-swal-popup',
        title: 'custom-swal-title',
        content: 'custom-swal-content',
        icon: 'custom-swal-icon',
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      }
    });

    if (result.isConfirmed) {
      try {
        setLoading(true);
        const response = await axios.delete(`${config.apiBaseUrl}/menus/delete_fullmenucategory/${categoryId}`);
        if (response.status === 200) {
          toast.success(response.data.message); fetchMenuDetails();
        } else {
          toast.error(response.data.message || "Failed to delete the category.");
        }
      } catch (error) {
        toast.error(error.response?.data?.error || "An error occurred while deleting the category.");
      }
    }
  };



  const handleDeletesub = async (sub_category_id) => {
    const result = await Swal.fire({
      title: "Are you sure you want to delete this subcategory?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      confirmButtonText: 'Yes, delete it!',

      customClass: {
        popup: 'custom-swal-popup',
        title: 'custom-swal-title',
        content: 'custom-swal-content',
        icon: 'custom-swal-icon',
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      }
    });
    if (result.isConfirmed) {
      try {
        const response = await axios.delete(`${config.apiBaseUrl}/menus/delete_fullmenu_subcategory/${sub_category_id}`);
        if (response.status === 200) {
          toast.success(response.data.message); fetchMenuDetails();
        } else { toast.error("Failed to delete category."); }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error(error.response.data.message);
        } else {
          console.error('An error occurred:', error);
          toast.error('An error occurred while deleting the subcategory.');
        }
      }
    }
  };

  const handleMenuTypeChange = (e) => { setMenuType(e.target.value); };

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_fullmenu_subcatagorizes_list`)
      .then((response) => {
        setInsertCategories(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the categories!", error);
        toast.error("Error fetching categories, please try again later.");
      });
  }, []);

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value; setCategorySelected(categoryId);

    const selectedCat = insertCategories.find(
      (cat) => cat.category_id === parseInt(categoryId)
    );
    setSelectedSubCategory(selectedCat ? selectedCat.sub_categories : []);
    setSubCategorySelected("");
  };

  const handleSubCategoryChange = (e) => { setSubCategorySelected(e.target.value); };

  const handleDishNameChange = (e) => { setDishName(e.target.value); };

  const fetchMenuDetails = async () => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/menus/get_all_web_menu_details`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching the data", error);
      toast.error("Error fetching menu details, please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchMenuDetails();
  }, []);

  const deleteMenuItem = async (Menu_Id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this menu?"
    );
    if (!isConfirmed) return;
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/menus/Delete_Menu`,
        { menu_id: Menu_Id }
      );
      if (response.status === 200) {
        toast.success("Menu details deleted successfully.");
        fetchMenuDetails("");
        console.log("Menu deleted successfully:", response.data);
      }
    } catch (error) {
      console.error(
        "Error deleting menu:",
        error.response?.data || error.message
      );
      toast.error("An error occurred while deleting menu details.");
    }
  };

  const handleCategoryClick = (categoryId) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryId);
    }
  };

  const handleinsermenuOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setCreatemenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleinsermenuOutside);
    return () => {
      document.removeEventListener("mousedown", handleinsermenuOutside);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/get_ingredients_unit`)
      .then((response) => {
        setUoms(response.data);
        console.log("Fetched UOMs:", response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the UOMs!", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${config.apiBaseUrl}/menus/Get_Vendor_ingredient_details`)
      .then((response) => {
        setIngredientData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleTypeChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].type = value;

    const selectedTypeData = ingredientData.find(
      (type) => type.vendor_id === parseInt(value)
    );
    if (selectedTypeData) {
      newIngredients[index].availableIngredients = selectedTypeData.ingredients;
    } else {
      newIngredients[index].availableIngredients = [];
    }

    setIngredients(newIngredients);
  };
  const handleItemChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].name = value;
    setIngredients(newIngredients);
  };

  const handleUOMChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index].uom = value;
    setIngredients(newIngredients);
  };

  const handleIngredientChange = (index, key, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index][key] = value;
    setIngredients(newIngredients);
  };

  const addIngredient = () => {
    setIngredients([
      ...ingredients,
      { type: "", name: "", quantity: "", uom: "", availableIngredients: [] },
    ]);
  };

  const removeIngredient = (index) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients.splice(index, 1);
    setIngredients(updatedIngredients);
  };

  const handlecdardmenu = async (menuId) => {
    setSelectedMenuId(menuId);
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/menus/Get_Edit_Menu_Details`,
        { menu_id: menuId }
      );

      if (response.status === 200 && response.data.length > 0) {
        const menuDetails = response.data[0].menu_details[0];

        setDishName(menuDetails.Menu_Name || "");
        setMenuType(menuDetails.Menu_Type?.toLowerCase() || "");
        setCategorySelected(menuDetails.CATEGORY_ID || "");

        // Populating Subcategory Data
        const selectedCat = insertCategories.find(
          (cat) => cat.category_id === menuDetails.CATEGORY_ID
        );
        setSelectedSubCategory(selectedCat ? selectedCat.sub_categories : []);
        setSubCategorySelected(menuDetails.sub_category_ID || "");

        // Populating Ingredients Data with Ingredient_id
        const ingredientsData = response.data[0].menu_details
          .filter((detail) => detail.Ingredient_id !== null) // Only include valid ingredients
          .map((detail) => ({
            type: detail.Vendor_Id || "",
            name: detail.Name || "",
            quantity: detail.Quantity_value || "",
            uom: detail.UOM || "",
            Ingredient_id: detail.Ingredient_id,
            availableIngredients: [],
          }));

        // Fetch available ingredients for each vendor type
        ingredientsData.forEach((ingredient, index) => {
          const selectedTypeData = ingredientData.find(
            (type) => type.vendor_id === parseInt(ingredient.type)
          );
          if (selectedTypeData) {
            ingredientsData[index].availableIngredients =
              selectedTypeData.ingredients;
          }
        });

        setIngredients(ingredientsData);
      } else {
        toast.error("Failed to fetch menu details");
      }
    } catch (err) {
      toast.error(
        err.response?.data?.error ||
        "An unexpected error occurred while fetching data"
      );
    }
    setCreatemenuOpen(true);
  };

  const deleteMenuIngredient = (Ingredient_id) => {
    fetch(`${config.apiBaseUrl}/menus/deleteItemIngredientsByMenuId/${Ingredient_id}`, { method: "DELETE", })
      .then((response) => {
        if (response.ok) {
          console.log("Delete successful");
          toast.success("Delete successful");
        } else {
          toast.error("Delete failed");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updateInsertIngredients = async (menuId) => {
    if (!menuId) {
      toast.error("No menu selected.");
      return;
    }
    if (!ingredients || ingredients.length === 0) {
      toast.error("No ingredients to update.");
      return;
    }
    try {
      const ingredientsData = ingredients.map((ingredient) => ({
        p_Menu_Id: menuId,
        p_Name: ingredient.name,
        p_UOM: ingredient.uom,
        p_Vendor_id: ingredient.type,
        p_Ingredient_id: parseFloat(ingredient.Ingredient_id),
        p_Quantity_value: parseFloat(ingredient.quantity),
      }));

      const response = await axios.post(
        `${config.apiBaseUrl}/menus/insertOrUpdateItemIngredientsBulk`,
        { ingredients: ingredientsData }
      );
      if (response.data.message) {
        toast.success(response.data.message);
        fetchMenuDetails();
      }
      if (response.data.details) {
        console.log("Processed Ingredients:", response.data.details);
      }
    } catch (error) {
      console.error("Error occurred during the saving process:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Error occurred while saving ingredients.";
      toast.info(errorMessage);
    }
  };

  const updateMenu = async () => {
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/menus/update_fullmenu_details`,
        {
          menu_id: selectedMenuId,
          menu_type: menuType,
          menu_name: dishName,
          sub_category_id: subCategorySelected,
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message || "Menu updated successfully");
        fetchMenuDetails();
      } else {
        toast.error(response.data.error || "Failed to update menu details");
      }
    } catch (err) {
      toast.error(
        err.response?.data?.error ||
        "An unexpected error occurred while updating data"
      );
    }
  };



  return (
    <div className="menus">
      {openSubPopup && (
        <Addsubcategory
          openSubPopup={openSubPopup}
          handleCloses={handleCloses}
          fetchMenuDetails={fetchMenuDetails}
          categoryId={selectedCategoryIdForSub}
          subcategoryToEdit={subcategoryToEdit}
        />
      )}

      {openSubeditPopup && (
        <Editsubcategory
          openSubeditPopup={openSubeditPopup}
          handleCloseseditsub={handleCloseseditsub}
          subcategoryToEdit={subcategoryToEdit}
          fetchMenuDetails={fetchMenuDetails}
        />
      )}

      {openeditcategoryPopup && (
        <EditMaincategory
          categoryId={editcategoryId}
          initialCategoryName={categoryNames}
          openeditcategoryPopup={openeditcategoryPopup}
          handleditcategoryclose={handleditcategoryclose}
          fetchMenuDetails={fetchMenuDetails}
        />
      )}


      {openPopup && (
        <div className="popup-overlaycataory">
          <div className="menusopencatagry">
            <h5 className="menuheadxcatagry text-center mb-0">
              Add Main Category
            </h5>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-11 col-md-10 col-sm-12 col-11 p-2">
                <input
                  type="text"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  placeholder="Enter Main Category Name"
                  className="form-group-inputcatary p-2"
                />
              </div>
            </div>
            <div className="button-grouaddcatary">
              <button className="closecatary-savebtn" onClick={handleClose}>
                Close
              </button>
              <button
                className="catary-savebtn"
                onClick={handleInsertCategory}
                disabled={!categoryName.trim() || loading}
              >
                save
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
      <h5 className="menuheadx mt-2">Menus</h5>
      <div className="addlistdiv">
        <button className="Adddishbtn" onClick={handleOpen}>
          Add Main Category
        </button>
      </div>
      {CreatemenuOpen && (
        <div className="overlayadd ">
          <div ref={menuRef} className="menunamecreate mt-4">
            <h5 className="adddishead">Create menus</h5>
            <div className="dishdivchoose">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  <h6 className="catagris">Menu Type</h6>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="inlineRadio1"
                      name="categoryType"
                      value="veg"
                      checked={menuType === "veg"}
                      onChange={handleMenuTypeChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Veg
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="inlineRadio2"
                      name="categoryType"
                      value="non-veg"
                      checked={menuType === "non-veg"}
                      onChange={handleMenuTypeChange}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Non-veg
                    </label>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                  <h6 className="catagris">Main categories</h6>
                  <select
                    className="form-select"
                    value={categorySelected}
                    onChange={handleCategoryChange}
                  >
                    <option value="" disabled>
                      Choose Main categories
                    </option>
                    {insertCategories.map((category) => (
                      <option
                        key={category.category_id}
                        value={category.category_id}
                      >
                        {category.category_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4  col-md-4 col-sm-12 col-12">
                  <h6 className="catagris">Sub categories</h6>
                  <select
                    className="form-select"
                    value={subCategorySelected}
                    onChange={handleSubCategoryChange}
                  >
                    <option value="" disabled>
                      Choose Sub categories
                    </option>
                    {selectedSubCategory.map((subcategory) => (
                      <option
                        key={subcategory.sub_category_ID}
                        value={subcategory.id}
                      >
                        {subcategory.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row form-group">
                <div className=" col-12">
                  <h6 className="ingred mt-4">Dish Name</h6>
                  <input
                    type="text"
                    value={dishName}
                    placeholder="Enter dish name"
                    className="form-group-input"
                    onChange={handleDishNameChange}
                  />
                </div>
              </div>
              <h5 className="mt-4">Ingredients</h5>
              <div className="scrolldivmenus">
                {ingredients.length === 0 ? (
                  <p className="no-data-message">No more data found</p>
                ) : (
                  ingredients.map((ingredient, index) => (
                    <div className="row form-group mt-3" key={index}>
                      <div className="col-lg-3 col-6 mt-1">
                        <select
                          className="form-select"
                          value={ingredient.type}
                          onChange={(e) =>
                            handleTypeChange(index, e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Ingredient Type
                          </option>
                          {ingredientData.map((ingredientType) => (
                            <option
                              key={ingredientType.vendor_id}
                              value={ingredientType.vendor_id}
                            >
                              {ingredientType.type}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-3 col-6 mt-1">
                        <select
                          className="form-select"
                          value={ingredient.name}
                          onChange={(e) =>
                            handleItemChange(index, e.target.value)
                          }
                          disabled={!ingredient.availableIngredients.length}
                        >
                          <option value="" disabled>
                            Ingredient Name
                          </option>
                          {ingredient.availableIngredients.map(
                            (availableIngredient) => (
                              <option
                                key={availableIngredient.ingredient_name}
                                value={availableIngredient.ingredient_name}
                              >
                                {availableIngredient.ingredient_name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="col-lg-3 col-6 mt-1">
                        <input
                          type="text"
                          value={ingredient.quantity}
                          className="form-group-input"
                          onChange={(e) =>
                            handleIngredientChange(
                              index,
                              "quantity",
                              e.target.value
                            )
                          }
                          placeholder="Quantity"
                        />
                      </div>
                      <div className="col-lg-2 col-5 mt-1">
                        <select
                          className="form-select"
                          value={ingredient.uom}
                          onChange={(e) =>
                            handleUOMChange(index, e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Choose Unit
                          </option>
                          {Uoms.map((unit) => (
                            <option key={unit.UOM} value={unit.UOM}>
                              {unit.UOM}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className="col-lg-1 col-1 mt-1 p-0 d-flex justify-content-center "
                        key={ingredient.Ingredient_id}
                      >
                        <FontAwesomeIcon
                          className="pt-3 remove-btn"
                          onClick={() => {
                            deleteMenuIngredient(ingredient.Ingredient_id);
                            removeIngredient(index);
                          }}
                          icon={faXmark}
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div className="button-groucatary d-flex flex-column flex-md-row justify-content-end align-item-center mt-2 gap-2">
              <button
                type="button"
                className="closecatary-savebtn btn btn-primary"
                onClick={addIngredient}
              >
                Add Ingredient
              </button>
              <button
                type="button"
                onClick={() => updateInsertIngredients(selectedMenuId)}
                className="catary-savebtn btn btn-success"
              >
                Update Ingredient
              </button>
              <button
                type="button"
                onClick={() => updateMenu(selectedMenuId)}
                className="catary-savebtn btn btn-warning"
              >
                Update Menu
              </button>
            </div>

          </div>
        </div>
      )}

      {loading ? (
        <div className="spinner-container">
          <div className="sweet-loading">
            <PulseLoader
              color={color}
              loading={loading}
              size={12}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      ) : (
        <div className="row mt-4 d-flex justify-content-center">
          {categories.map((category) => (
            <div key={category.category_Id} className="col-lg-10 col-12 mt-2">
              <div className="beavergecatagry"  >
                <div className="row align-items-center">
                  <div className="col-lg-11 col-md-11 col-sm-10 col-10">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="category-name ellipsis mb-0">{category.category_name}</h6>
                      <span className="icon-group">
                        <FontAwesomeIcon className="ms-3 faEditcategory" onClick={() => handleditcategoryOpen(category.category_Id, category.category_name)} icon={faEdit} data-tooltip-id="Editcategor-tooltip" data-tooltip-content="Edit category" />
                        <Tooltip id="Editcategor-tooltip" place="left" className="custom-fade-tooltip" />
                        <FontAwesomeIcon className="ms-3 faTrashcategory" onClick={() => handleDelete(category.category_Id)} icon={faTrashCan} data-tooltip-id="deletecategor-tooltip" data-tooltip-content="Delete category" />
                        <Tooltip id="deletecategor-tooltip" place="right" className="custom-fade-tooltip" />
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-2 col-2 d-flex justify-content-end ">
                    <FontAwesomeIcon
                      onClick={() => handleCategoryClick(category.category_Id)}
                      className={`chevronmenusicon ${selectedCategory === category.category_Id ? "rotate" : ""}`}
                      icon={faChevronDown}
                    />
                  </div>
                </div>
              </div>

              {selectedCategory === category.category_Id && (
                <div className={`row subdiv ${selectedCategory === category.category_Id ? "show" : ""}`}  >
                  <h6 className="text-end p-0 mb-0" >
                    <FontAwesomeIcon onClick={() => handlesubOpen(category.category_Id)} className="pluseiconsubadd" icon={faPlus} data-tooltip-id="add-subcategory-tooltip" data-tooltip-content="Add subcategory" />
                    <Tooltip id="add-subcategory-tooltip" place="right" className="custom-fade-tooltip" />
                  </h6>

                  {category.sub_categories && category.sub_categories.length > 0 ? (
                    category.sub_categories.map((subCategory) => (
                      <div
                        key={subCategory.sub_category_id}
                        className="sub-catagry"
                      >
                        <h5 className="sub-catagry-name d-flex justify-content-between align-items-center col-lg-4 col-md-5 col-sm-12 col-12">
                          {subCategory.sub_category_name}
                          <span className="icon-group ms-auto">
                            <FontAwesomeIcon
                              className="faEdit"
                              icon={faEdit}
                              onClick={() => handleSubEdit(subCategory)}
                              data-tooltip-id="edit-subcategory-tooltip"
                              data-tooltip-content="Edit subcategory"
                            />
                            <Tooltip id="edit-subcategory-tooltip" place="top" className="custom-fade-tooltip" />
                            <FontAwesomeIcon
                              className="ms-3 faTrash"
                              onClick={() => handleDeletesub(subCategory.sub_category_id)}
                              icon={faTrashCan}
                              data-tooltip-id="delete-subcategory-tooltip"
                              data-tooltip-content="Delete subcategory"
                            />
                            <Tooltip id="delete-subcategory-tooltip" place="right" className="custom-fade-tooltip" />
                          </span>
                        </h5>
                        <div className="row mt-4">
                          {subCategory.menus.map((menu) => (
                            <div
                              key={menu.Menu_Id}
                              className="col-lg-2 col-md-3 col-sm-4 col-6 mb-2 mt-1 position-relative"
                            >
                              <button
                                className="btn btn-light menu-button w-100"
                                onClick={() => handlecdardmenu(menu.Menu_Id)}
                              >
                                {menu.Menu_Name}
                              </button>
                              <span
                                className="delete-icon position-absolute"
                                onClick={() => deleteMenuItem(menu.Menu_Id)}
                              >
                                <FontAwesomeIcon icon={faXmark} />
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-found">
                      <p className="mb-">No Subcategories Found</p>
                    </div>
                  )}


                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
