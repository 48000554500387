import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../src/component/pages/Dashboard";
import Sidebar from "../src/component/sidebar/sidebar";
import Login from "../src/component/login/login";
import { Menus } from './component/pages/Menus';
import { Manager } from './component/management/manager';
import { Vendors } from './component/management/vendors';
import { Upcommingorders } from './component/pages/Upcommingorders';
import Emailotp from './component/forgetpassword/EmailOtp';
import Setpassword from './component/forgetpassword/Setpassword';
import PrivateRoute from '../src/component/privaterout'; 
import ChatHistoryPage from './component/Chathistory/Userhistory';
import ChatHistory from './component/Chathistory/chathistory';
import Fixedmenus from './component/pages/Fixedmenus';
import { Leads } from './component/pages/Leads';
import Ingredient from './component/pages/Ingredient';
import Adddish from './component/pages/Adddish';
function MainLayout() {
  return (
    <div className="main__layout">
   <Sidebar />
      <div className="content">
        <Routes>
          <Route path="/dashboard" element={<PrivateRoute element={Dashboard}/>} />
          <Route path="/menus" element={<PrivateRoute element={Menus} />}/>
          <Route path="/Add-dish" element={<PrivateRoute element={Adddish} />}/>
          <Route path="/fixedmenus" element={<PrivateRoute element={Fixedmenus} />}/>
          <Route path="/Ingredient" element={<PrivateRoute element={Ingredient} />}/>
          <Route path="/Manager" element={<PrivateRoute element={Manager}/>}/>   
          <Route path="/Vendors" element={<PrivateRoute element={Vendors}/>}/>
          <Route path="/Orders" element={<PrivateRoute element={Upcommingorders}/>}/>
          <Route path="/Leads" element={<PrivateRoute element={Leads}/>}/>
          <Route path="/Chat-history" element={<PrivateRoute element={ChatHistory} />}/>
          <Route path="/chat-history/:event" element={<PrivateRoute element ={ChatHistoryPage}/>} />
       
          
        </Routes>
      </div>
  
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<MainLayout />} />
        <Route path="/Sendmailotp" element={<Emailotp />} />
        <Route path="/Setpassword" element={<Setpassword />} />
        <Route path="/login" element={<Login />} /> 
      </Routes>
    </BrowserRouter>
  );
}

export default App;
